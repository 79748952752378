<template>
    <div class="a-f-container" :class="{ active: isActive }">
      <div class="a-overlay" @click="toggleForm"></div>
      <div class="a-feedback-form">
        <button class="a-close-btn" @click="toggleForm"></button>
        <h3>Заявка</h3>
        <form @submit.prevent="submitForm">
            <input type="text" v-model="name" placeholder="Имя" required>
            <input type="tel" v-model="phone" placeholder="Телефон" required>
            <input type="checkbox"  id="che1" v-model="agreement" required>
            <label for="che1">Согласие на обработку персональных данных</label>
          <button type="submit" class="a-btn a-btn-2">Отправить</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        name: '',
        phone: '',
        agreement: false,
        isActive: false,
      };
    },
    methods: {
      toggleForm() {
        this.isActive = !this.isActive;
      },
      submitForm() {
        // Обработка отправки формы
        console.log('Форма отправлена:', this.name, this.phone, this.agreement);
        // Закрыть форму после отправки
        this.toggleForm();
        // Очистить поля формы
        this.name = '';
        this.phone = '';
        this.agreement = false;
      }
    }
  };
  </script>
  
  <style scoped>
  .a-f-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s;
  }
  
  .a-f-container.active {
    visibility: visible;
    opacity: 1;
  }
  
  .a-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  .a-feedback-form {
    box-shadow: -40px 0px 33px -44px #a7a7a7;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 442px;
    max-height: 100%;
    width: 416px;
    padding: 20px;
    background: #fff;
    transform: translateX(100%);
    transition: transform 0.3s;
  }
@media (max-width: 601px) {
    .a-feedback-form {
        box-shadow: -40px 0px 33px -44px #a7a7a7;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 442px;
        width: 100%;
        padding: 20px;
        background: #fff;
        transform: translateX(100%);
        transition: transform 0.3s;
      }
}
  .a-f-container.active .a-feedback-form {
    transform: translateX(0);
  }
  
  .a-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: inline-block;
  }

  .a-close-btn::before, .a-close-btn::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2px;
    height: 24px; /* Длина линий */
    background-color: rgb(64, 64, 64); /* Цвет линий */
  }
  .a-close-btn::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .a-close-btn::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }


input[type="text"],input[type="tel"] {
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    border: 1px solid #a7a7a7;
    width: 100%;
    margin-top: 17px;
}
/* Скрываем стандартный чекбокс */
input[type="checkbox"] {
    display: none;
}
/* Создаем новый квадратный блок для нашего чекбокса */
input[type="checkbox"] + label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    color: #666;
    font-size: 14px;
    margin-top: 20px;
}

input[type="checkbox"] + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: -6px;
    width: 25px;
    height: 25px;
    border: 2px solid #c3bbdb;
    border-radius: 3px;
    background-color: #fff;
}

/* Стилизуем чекбокс, когда он в состоянии "checked" */
input[type="checkbox"]:checked + label:before {
    content: "\2713"; 
    text-align: center;
    line-height: 25px;
    color: #666;
}
  </style>