<template>
  <div>
    <vApartCom :idCom=pComplexId />
    <!-- <test/> -->
  </div>
</template>

<script>
import vApartCom from './components/modules/v-apart-com.vue'
// import test from './components/DemoComponent.vue'

export default {
  name: 'App',
  data() {
      return {
          pContainer: window.apartConfig['container'],
          pComplexId: window.apartConfig['idCom']
      }
  },
  components: {
    vApartCom,
    // test
  }
}
</script>

<style lang="scss">


</style>
