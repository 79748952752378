<template>
    <div>
        <div class="a-slide_plans">
            <div :class=pContainer  style="padding-top: 0; padding-bottom: 0;">
                <div class="a-full-width">
                    <h3>Все планировки</h3>
                    <div class="a-navigates">
                        <input class="a-btn-check" type="radio" name="exampleRadios" id="radio" value="0" v-model="selectedApartmentIndex" :disabled="!apgroup[0].length" :checked="selectedApartmentIndex === '0'" hidden>
                        <label class="a-btn a-btn-3" for="radio">Ст</label>
                        <input class="a-btn-check" type="radio" name="exampleRadios" id="radio1" value="1" v-model="selectedApartmentIndex" :disabled="!apgroup[1].length" :checked="selectedApartmentIndex === '1'" hidden>
                        <label class="a-btn a-btn-3" for="radio1">1</label>
                        <input class="a-btn-check" type="radio" name="exampleRadios" id="radio2" value="2" v-model="selectedApartmentIndex" :disabled="!apgroup[2].length" :checked="selectedApartmentIndex === '2'" hidden>
                        <label class="a-btn a-btn-3" for="radio2">2</label>
                        <input class="a-btn-check" type="radio" name="exampleRadios" id="radio3" value="3" v-model="selectedApartmentIndex" :disabled="!apgroup[3].length" :checked="selectedApartmentIndex === '3'" hidden>
                        <label class="a-btn a-btn-3" for="radio3">3</label>
                        <input class="a-btn-check" type="radio" name="exampleRadios" id="radio4" value="4" v-model="selectedApartmentIndex" :disabled="!apgroup[4].length" :checked="selectedApartmentIndex === '4'" hidden>
                        <label class="a-btn a-btn-3" for="radio4">4+</label>
                        <div style="float: right; display: flex;">
                            <div ref="prev" class="swiper-button-prev a-btn a-btn-3"></div>
                            <div ref="next" class="swiper-button-next a-btn a-btn-3"></div>
                        </div>
                    </div>
                </div>
                <div calss="a-slider" style="height: max-content; z-index: 0;">
                    <Swiper
                        :slides-per-view="7"
                        :space-between="10"
                        :breakpoints="{
                        100: { slidesPerView: 2, spaceBetween: 10 },
                        400: { slidesPerView: 3, spaceBetween: 10 }, // Пример промежуточной точки
                        500: { slidesPerView: 4, spaceBetween: 10 }, // Пример промежуточной точки
                        600: { slidesPerView: 5, spaceBetween: 10 }, // Еще один пример
                        700: { slidesPerView: 6, spaceBetween: 10 }, // Пример промежуточной точки
                        1000: { slidesPerView: 7, spaceBetween: 10 }
                        }"
                        :freeMode ="true"
                        :modules ="modules"
                        :navigation="{
                            prevEl: prev,
                            nextEl: next,
                        }"
                    >
                        <SwiperSlide class="a-card-text" v-for="(apartment, index) in apgroup[selectedApartmentIndex]" :key="index">
                        <div class="a-card-plan" :class="{'a-card-active':apartment.id == showApartment.id}" @click="findApart(apartment.id)">
                            <div>
                            <div class="a-imgsl">
                                <img class="a-min_plan" :src="'https://xn--b1amcelbhbatkm8b.xn--p1ai/img/apartment/'+ apartment.id +'.jpg'">
                            </div>
                            <p>{{ apartment.square }} м2</p>
                            <!-- <router-link :to="{ path: '/complex/'+ record.url  }">Перейти к продукту</router-link> -->
                            </div>
                        </div> 
                        <div class="a-toapart" v-if="apartment.id == showApartment.id"></div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
        <div :class= pContainer style="padding-top: 0; padding-bottom: 0;">
            <div class="a-full-width a-bottom-div" v-if="showApartment">
                <FeedbackForm ref="feedbackForm" style="z-index: 10;"/>
                
                    <div class="a-column a-image-column">
                        <img class="a-big_plan a-shad-box-apart" :src="'https://xn--b1amcelbhbatkm8b.xn--p1ai/img/apartment/'+ showApartment.id +'.jpg'" alt="Плариновка квартиры">
                    </div>
                    <div class="a-column a-text-column a-col-apart"><h4>
                        {{ showApartment.rooms == 0 ? 'Студия '+showApartment.square : showApartment.rooms+'-комнатная '+showApartment.square }} м<sup style="font-size: 15px;">2</sup></h4>
                        <table>
                            <tr>
                                <td>Конструктив:</td>
                                <td>{{ showApartment.wall }}</td>
                            </tr>
                            <tr>
                                <td>Срок сдачи:</td>
                                <td>{{ showApartment.deadline }}</td>
                            </tr>
                            <tr>
                                <td>Площадь:</td>
                                <td>{{ showApartment.square }} м<sup>2</sup></td>
                            </tr>
                            <tr>
                                <td>На этажах:</td>
                                <td>{{ showApartment.floor }}</td>
                            </tr>
                            <tr>
                                <td>Цена за м<sup>2</sup>:</td>
                                <td>от {{ showApartment.price }} руб</td>
                            </tr>
                        </table>
                        <div class="a-price-apart">от <span>{{ (Math.round((showApartment.price * showApartment.square) / 100) * 100).toLocaleString('ru-RU') }}</span> руб.</div>
                        <div v-if="pButt !== ''" v-html="pButt"></div>
                        <div v-if="pButt == ''" >  
                            <a class="a-btn a-btn-1" @click="showForm"><div>Бронирование</div></a>
                            <a class="a-btn a-btn-2" @click="showForm"><div>Записаться на просмотр</div></a>
                        </div>
                    </div>
                    <div class="a-text-column a-block-bottom">
                        <div class="a-texttosale">{{ pText }}</div>
                    </div>
            
            </div>
            <div v-else class="a-preload">
                <div class="loader">loading</div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import { ref } from 'vue';
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import 'swiper/css';
    import 'swiper/css/free-mode';
    import { FreeMode } from 'swiper/modules';
    import FeedbackForm from '../modules/FeedbackForm.vue';
    import { Navigation } from 'swiper/modules'
    import 'swiper/css/navigation'

    export default {
        name: 'v-apartcom',
        // props: ['api_key'],
        data() {
            return {
                apartments: [],
                apgroup:  [[], [], [], [], []],
                selectedApartmentIndex: '0',
                apId: '',
                apikey: window.apartConfig['api_key'],
                pContainer: window.apartConfig['container'],
                pText: window.apartConfig['text'],
                pButt: window.apartConfig['button'],
            }
        },

        components: {
            Swiper,
            SwiperSlide,
            FeedbackForm,
        },
        setup() {
            const onSwiper = (swiper) => {
                console.log(swiper);
            };
            const onSlideChange = () => {
                console.log('slide change');
            };
            const prev = ref(null);
            const next = ref(null);
            return {
                onSwiper,
                onSlideChange,
                modules: [Navigation, FreeMode],
                prev,
                next,
            };
        },
        computed: {
            showApartment(){
                return this.apartments.find(apart => apart.id === this.apId)
            }
        },
        methods: {
            getFromApi(key){
                return axios.get(process.env.VUE_APP_API_URL_P+`?api_key=`+key+`&mod=`+process.env.VUE_APP_IDMOD)
                    .then(({ data }) => {
                        this.apartments = data.records
                        console.log(data.qwerty);
                        // Группировка апартаментов по количеству комнат
                        const groupedByRooms = data.records.reduce((acc, apartment) => {
                            // Существует ли уже массив для этой группы комнат? Если нет, создать его.
                            acc[apartment.rooms] = acc[apartment.rooms] || [];
                            // Добавить текущий объект к соответствующей группе
                            acc[apartment.rooms].push(apartment);
                            return acc;
                        }, {});
                        
                        this.apgroup[0] = groupedByRooms['0'] || [];
                        this.apgroup[1] = groupedByRooms['1'] || [];
                        this.apgroup[2] = groupedByRooms['2'] || [];
                        this.apgroup[3] = groupedByRooms['3'] || [];
                        this.apgroup[4] = groupedByRooms['4'] || [];
                       
                        for (let i = 0; i <= 4; i++) {
                            if (Array.isArray(groupedByRooms[i.toString()]) && groupedByRooms[i.toString()].length > 0) {
                                this.selectedApartmentIndex = i.toString();
                                this.apId = this.apgroup[this.selectedApartmentIndex][0].id
                                break;
                            }
                        }
                    })
                .catch((error) => {
                    console.log(error)
                });
            },
            selectApartment(index) {
                // Проверка на наличие элемента в массиве
                if (this.apgroup[index]) {
                    this.selectedApartmentIndex = index
                    this.apId = this.apgroup[index][0].id
                } else {
                    console.log('Элемент с таким индексом не найден в массиве apartments');
                }
            },
            findApart(id){
                this.apId = id
            },
            showForm() {
                this.$refs.feedbackForm.toggleForm();
            }
        },
        watch: {
            selectedApartmentIndex(newValue) {
                this.selectApartment(parseInt(newValue));
            }
        },
        mounted() {
            this.getFromApi(this.apikey)
        }
    }
</script>

<style scoped>
.a-price-apart {
    font-size: 20px;
}
.a-slider {
    padding: 50px 0;
}
.swiper {
    width: 100%;
}
.a-imgsl{
    width: 100%;
    margin-bottom: 10px;
    background-size: cover;
    background-position: 50% 50%;
}

#detal_apart_window {
    width: 100%;
}
#apart_img_block img{
    width: 380px;
    height: auto;
}
.info-block {
    line-height: 28px;
    margin: 0px 40px;
    max-width: 400px;
}
.a-big_plan {
    width: 100%;
    max-width: 389px;
    min-width: 100px;
}
.a-min_plan {
    width: 90%;
    margin: 4px;
}
.a-card-text {
    text-align: center;
}
.a-card-text p {
    margin: 2px;
}
.a-slide_plans {
    background: #f1f1f1;
    padding: 24px 24px 0;
    user-select: none;
}
.info-desc {
    --bs-gutter-x: 0;
    margin: 0 25px;
}
.a-shad-box-apart {
    box-shadow: 0 44px 23px -30px rgb(191 191 191 / 45%);
}
table {
    width: 100%; /* Задаем ширину таблицы */
    border-collapse: collapse; /* Убираем двойные границы между ячейками */
    margin: 20px 0; /* Добавляем отступ сверху и снизу таблицы */
  }
  
  /* Стили для ячеек таблицы */
  th,
  td {
    border: 1px solid #ddd; /* Граница вокруг ячеек */
    padding: 8px; /* Отступы внутри ячеек */
    text-align: left; /* Выравнивание текста по левому краю */
  }
  
  /* Стили для заголовков таблицы */
  th {
    background-color: #f9f9f9; /* Цвет фона заголовков */
    color: #333; /* Цвет текста заголовков */
  }
  
  /* Чередование цветов строк */
  tr:nth-child(even) {
    background-color: #f2f2f2; /* Цвет фона для четных строк */
  }
  
  tr:nth-child(odd) {
    background-color: #ffffff; /* Цвет фона для нечетных строк (обычно белый, можно не указывать) */
  }
  
  /* Стили при наведении курсора на строку */
 /* tr:hover {
    background-color: #e8e8e8; 
 } */
 ul, ol {
    margin-left: 20px;
    margin-bottom: 15px;
}

ul li, ol li {
    line-height: 1.6;
}

.swiper-button-prev, .swiper-button-next {
    position: unset !important;
    width: auto !important;
    height: auto !important;
    margin: 15px 0 0px 3px !important;
    width: 35px !important;
    --swiper-navigation-size: 20px !important;
}
.a-card-active{
    border: 1px solid #ffd079;
}
   .a-toapart {
    margin-bottom: 24px;
    position: relative;
   }
   .a-toapart::before, .a-toapart::after {
    content: ''; 
    position: absolute;
    left: 20px; bottom: -20px;
    border: 10px solid transparent;
    border-top: 10px solid #ffd079;
   }
   .a-toapart::after {
    border-top: 10px solid white;
    bottom: -19px; 
   }


.a-preload {
    text-align: center;
    padding: 105px 0;
}
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #65abf8;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

</style>