import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  state: {
    recdata: []
  },
  getters: {
    RECDATA(state) {
      return state.recdata;
    }
  },
  mutations: {
    SET_RECORDS: (state, data) => {
      state.recdata = data.records;
    }
  },
  actions: {
    GET_FROM_API({commit}) {
      return axios.get(process.env.VUE_APP_API_URL_C+'?api_key='+process.env.VUE_APP_API_KEY+'&')
        .then(({ data }) => {
          commit('SET_RECORDS', data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  modules: {
  }
})